<template>
 <div class="clien">
<!--   顶部导航-->
   <div class="dh">
     <div class="navigation">
       <div class="ico" @click="go()">
         <img class="icon" src="../assets/img15.png">
         <p class="designation">华钛信息</p>
       </div>
       <div class="selectivity">
         <div class="alternative">
           <div class="xzx1 xzxz1">
             <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
             <div class="nav1 nav">
               <div class="navxx">
                 <span>行业方案</span>
                 <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                 <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
               </div>
               <div class="lin-xx">
                 <div class="hz" v-for="(item, index) in hzData" :key="index">
                   <span class="lin-cut"></span>
                   <p @click="din(item)">{{item.hzz}}</p>
                 </div>
               </div>
               <div class="pt">
                 <img src="../assets/img188.png">
               </div>
             </div>
           </div>
           <div class="xzx2 xzxz1">
             <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
             <div class="nav2 nav">
               <div class="navxx">
                 <span>自主产品</span>
                 <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                 <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
               </div>
               <div class="lin-xx">
                 <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                   <span class="lin-cut"></span>
                   <p @click="din(item)">{{item.hzz}}</p>
                 </div>
               </div>
               <div class="pt">
                 <img src="../assets/img189.png">
               </div>
             </div>
           </div>
           <div class="xzx3 xzxz1">
             <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
             <div class="nav3 nav">
               <div class="navxx">
                 <span>服务范围</span>
                 <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                 <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
               </div>
               <div class="lin-xx">
                 <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                   <span class="lin-cut"></span>
                   <p @click="din(item)">{{item.hzz}}</p>
                 </div>
               </div>
               <div class="pt">
                 <img src="../assets/img190.png">
               </div>
             </div>
           </div>
           <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
             <span class="xzxz" @click="din(item)">{{item.cname}}</span>
           </div>
         </div>
       </div>
     </div>
   </div>
<!--第一层-->
   <div class="top">
     <div class="fl">
       <div class="bj">
         <div class="zss">
           <div class="bj-nr">
             <span>客户案例</span>
             <span>目前华钛信息拥有电商、金融、制造、零售等领域的诸多中<br>国五百强企业客户。</span>
           </div>
         </div>
       </div>
     </div>
   </div>
<!--   第二层-->
   <div class="clien-second">
     <div class="spreadhead">
       <div class="subhead">
         <div class="moit">
           <img class="img3" src="../assets/img3.png">
           <span>客户案例</span>
           <img class="img4" src="../assets/img4.png">
         </div>
       </div>
     </div>
     <div class="option-box">
       <div class="box3">
         <div class="box4">
           <div class="outer" v-for="(item , index) in loopDatas" :key="index">
               <span>{{item.cli1}}</span>
               <span>{{item.cli2}}</span>
               <span @click="din(item)">{{item.cli3}}</span>
           </div>
         </div>
       </div>
     </div>
   </div>
<!--   第三层-->
   <div class="clien-thirdy">
     <div class="t-fl">
       <div class="t-bj"></div>
     </div>
   </div>
<!--   底层-->
   <div class="ground-floor">
     <div class="fbox">
       <div class="fbox1">
         <div class="fbox2">
           <div class="lianxi">
             <div class="fico">
               <img class="ficon" src="../assets/img2.png">
               <p class="fdesignation">华钛信息</p>
             </div>
             <div class="contway">
               <div class="special">
                 <span>联系电话：</span>
                 <span>021-55962658</span>
               </div>
               <div class="special">
                 <span>商务合作：</span>
                 <span>business@huataish.com</span>
               </div>
             </div>
           </div>
           <div class="cut-line"></div>
           <div class="explain">
             <div class="explain1">
               <div class="rice">
                 <div class="rice1">
                   <span class="ricename">行业方案</span>
                   <ul>
                     <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                       <span @click="din(item)">{{ item.lei }}</span>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="rice">
                 <div class="rice1">
                   <span class="ricename">自主产品</span>
                   <ul>
                     <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                       <span @click="din(item)">{{ item.lei1 }}</span>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="rice">
                 <div class="rice1">
                   <span class="ricename">服务范围</span>
                   <ul>
                     <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                       <span @click="din(item)">{{ item.lei2 }}</span>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="rice">
                 <div class="rice1">
                   <span class="ricename" @click="cl()">客户案例</span>
                   <ul>
                     <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                       <span>{{ item.lei3 }}</span>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="rice">
                 <div class="rice1">
                   <span class="ricename" @click="hz()">合作伙伴</span>
                   <ul>
                     <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                       <span>{{ item.lei3 }}</span>
                     </li>
                   </ul>
                 </div>
               </div>
               <div class="rice">
                 <div class="rice1">
                   <span class="ricename" @click="xx()">华钛信息</span>
                   <ul>
                     <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                       <span @click="din(item)">{{ item.lei4 }}</span>
                     </li>
                   </ul>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="cut-line1"></div>
         <div class="safety">
           <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
           <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
         </div>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
export default {
  name: "Clien",
  data() {
    return {
      cur: 0,
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopDatas: [
        {
          cli1:"财富管理系统",
          cli2:"财富管理系统从资产配置、财务规划、能力展示、业务协同全面助理财富顾问进行数字化展业，帮助企业持续开展客群经营，通过系统落实财富管…",
          cli3:"定制化开发服务",
          url:'/wealth'

        },
        {
          cli1:"企业微信运营平台",
          cli2:"企业微信是私域流量最佳载体，以企业微信为核心的产品化解决方案，帮助客户实现由传统营销方式转为数字化营销管理模式。",
          cli3:"产品解决服务",
          url:'/enterprise'

        },
        {
          cli1:"智能投研策略中心",
          cli2:"智能投研策略中心主要应用于基金、券商等企业，帮助企业做好用户等级划分，在此基础上通过AI策略中心，自动分配相应投资级别的投资策略…",
          cli3:"平台研发服务",
          url:'/smart'

        },
        {
          cli1:"基金数据服务平台",
          cli2:"基金数据服务平台是一个将基金与全市场基金产品的信息提供统一化管理与数据报表可视化而建立的一个数据化服务管理平台。",
          cli3:"咨询服务",
          url:'/fund'

        },
        {
          cli1:"运维管理方案",
          cli2:"为杭州某龙头制造企业实施运维管理方案，包含定制化项目运维管理方案，运维交接方案，服务模式选取，知识转移，服务改进管理等模块。",
          cli3:"运维服务",
          url:'/maintenance'

        },
        {
          cli1:"外包服务",
          cli2:"服务于上海某龙头证券公司，培养相关IT人才并服务于其电子商务组、移动平台组、大数据开发组，帮助其开发内部IT系统并提供统一支持。",
          cli3:"外包服务",
          url:'/ectocyst'
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      foolDataes: [

      ],
      foolDataess: [

      ],
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }
}
</script>

<style scoped>
/*.clien{*/
/*  margin: 0 auto 0;*/
/*  position: relative;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  width: 100%;*/
/*}*/
/*标题*/
.spreadhead {
  width: 100%;
  height: 122px;
  margin-bottom: 48px;
  /*background: red;*/
}

.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}

.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 272px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}

.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}

.spreadhead .subhead .moit span {
  display: block;
  width: 96px;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}

.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第一层*/
.top{
  align-items: flex-end;
  display: flex;
  height: 538px;
  justify-content: center;
  position: relative;
  width: 100%;
}
.top .fl{
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.top .fl .bj{
  background-image: url("../assets/img25.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 538px;
  margin: 0 auto;
}
.top .fl .bj-nr{
  position: relative;
  top: 150px;
  display: flex;
  width: 416px;
  height: 134px;
  flex-direction: column;
  justify-content: space-between;
}
.top .fl .bj-nr span:first-child{
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 53px;
}
.top .fl .bj-nr span:last-child{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
}
/*第二层*/
.clien-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 740px;
}
.clien-second .option-box{
  flex: 1;
}
.clien-second .option-box .gao1{
  display: flex;
  flex-direction: column;
  width: 1180px;
  height: 334px;
  margin: 0 auto;
  /*background: #262C50;*/
}
.clien-second .option-box .gao1 .gao1-tab{
  width: 1180px;
  height: 39px;
  margin-bottom: 60px;
  border-bottom: 1px solid #D8D8D8;
}
.clien-second .option-box .gao1 .gao1-tab ul{
  width: 900px;
  height: 39px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.clien-second .option-box .gao1 .gao1-tab ul li{
  width: 56px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  text-align: center;
}
.clien-second .option-box .gao1 .gao1-tab ul li p:first-child.active{
  color: #124BFF;
}
.clien-second .option-box .gao1 .gao1-tab ul li .underline{
  margin-top: 14px;
  width: 56px;
  height: 2px;
  background: #124BFF;
}
.clien-second .option-box .gao2{
  flex: 1;
}
.clien-second .option-box .gao2 img{
  display: block;
  width: 1180px;
  height: 234px;
}
/*第三层*/
.clien-thirdy{
  align-items: flex-end;
  display: flex;
  height: 286px;
  justify-content: center;
  position: relative;
  width: 100%;
}
.clien-thirdy .t-fl{
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.clien-thirdy .t-fl .t-bj{
  background-image: url("../assets/img27.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.box3 {
  width: 100%;
  height: 562px;
}

.box4 {
  display: flex;
  flex-direction: row;
  width: 1186px;
  height: 473px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.box4 .outer {
  display: flex;
  flex-direction: column;
  height: 219px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 1);
  width: 380px;
  margin: 0 20px 20px 0;
  box-shadow: 3px 3px 16px 0px #DEE3ED;
  border: 1px solid #E5E7EF;
}

.box4 .outer span:nth-child(1){
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #292C3E;
  line-height: 25px;
  margin-top: 30px;
  margin-left: 20px;
}

.box4 .outer span:nth-child(2){
  width: 340px;
  height: 66px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #808C9F;
  line-height: 22px;
  margin-top: 18px;
  margin-left: 20px;
}

.box4 .outer span:nth-child(3){
  width: 126px;
  height: 32px;
  background: #ECEDF3;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #707787;
  line-height: 32px;
  margin-top: 24px;
  margin-left: 20px;
  text-align: center;
}

.outer:nth-child(3n) {
  margin-right: 0;
}

.outer:nth-last-child(-n + 3) {
  margin-bottom: 0;
}

</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}
</style>